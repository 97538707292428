import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Points } from "three";
import * as THREE from "three";
import { ScoutPointCloudMaterial } from "../materials/ScoutPointCloudMaterial";
import { PCDLoader } from "./PCDLoader";
import {
  availableColoringModes,
  DEF_HAS_INTENSITY,
  DEF_HAS_REFLECTIVITY,
} from "../materials/ScoutPointMaterial.glsl";

export const loadGLTF = (
  url: string,
  onLoad: (gltf: GLTF) => void,
  onProgress: (p: ProgressEvent<EventTarget>) => void,
  onError: (err: unknown) => void,
) => {
  const loader = new GLTFLoader();
  loader.load(url, onLoad, onProgress, onError);
};

let droneModelCache: GLTF | undefined;

export const loadPCD: (
  pathOrData: string | ArrayBuffer,
  onProgress?: (event: ProgressEvent) => void,
) => Promise<Points> = async (pathOrData: string | ArrayBuffer, onProgress) => {
  const onProgressFunc = onProgress ?? (() => {});
  return new Promise((resolve, reject) => {
    const loader = new PCDLoader();
    const onLoaded = (pcd: Points) => {
      if (pcd === undefined) {
        reject("Failed to load PCD " + pathOrData);
      }
      const { max, min } = new THREE.Box3().setFromObject(pcd);
      pcd.material = new ScoutPointCloudMaterial(
        min.z,
        max.z,
        window.innerHeight,
        { pointSize: 0.04 },
      );
      pcd.material.depthWrite = false;
      // Use PCD RGB colors in the shader if available
      pcd.material.vertexColors =
        (pcd.geometry as THREE.BufferGeometry).attributes["color"] !==
        undefined;

      pcd.material.defines = pcd.material.defines || {}; // Ensure defines object exists
      pcd.material.defines[DEF_HAS_INTENSITY] =
        (pcd.geometry as THREE.BufferGeometry).attributes["intensity"] !==
        undefined;
      pcd.material.defines[DEF_HAS_REFLECTIVITY] =
        (pcd.geometry as THREE.BufferGeometry).attributes["reflectivity"] !==
        undefined;

      resolve(pcd);
    };
    if (typeof pathOrData === "string") {
      loader.load(pathOrData, onLoaded, onProgressFunc, reject);
    } else {
      const points = loader.parse(pathOrData);
      onLoaded(points);
    }
  });
};

export interface PointCloudAttribute {
  name: string;
  code: number;
}

export const heightPointCloudAttribute: PointCloudAttribute = {
  name: "Height",
  code: availableColoringModes.height,
};

export const reflectivityPointCloudAttribute: PointCloudAttribute = {
  name: "Reflectivity",
  code: availableColoringModes.reflectivity,
};

export const intensityPointCloudAttribute: PointCloudAttribute = {
  name: "Intensity",
  code: availableColoringModes.intensity,
};

export const getPointCloudAttributesInPrioritizedOrder = (
  points: Points | undefined,
): PointCloudAttribute[] => {
  const defines =
    (points?.material as ScoutPointCloudMaterial | undefined)?.defines || {};
  const hasIntensity = defines[DEF_HAS_INTENSITY] === true;
  const hasReflectivity = defines[DEF_HAS_REFLECTIVITY] === true;
  const attr: PointCloudAttribute[] = [];
  if (hasReflectivity) {
    attr.push(reflectivityPointCloudAttribute);
  }
  if (hasIntensity) {
    attr.push(intensityPointCloudAttribute);
  }

  attr.push(heightPointCloudAttribute);

  return attr;
};

export const getDroneModel = async () => {
  if (droneModelCache === undefined) {
    droneModelCache = await new Promise((resolve, reject) => {
      loadGLTF(
        "/3d-assets/drone/Scout137_revD_lp.glb",
        resolve,
        () => {},
        reject,
      );
    });
  }
  return droneModelCache;
};
