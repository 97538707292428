import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import "react-reflex/styles.css";
import { VideoPane } from "./components/video-pane";
import { VideoJsPlayer } from "video.js";
import React, {
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { IGlobalMapConfig } from "./components/3dView/components/scout3DPlayer.tsx";
import { IFinding, ISession } from "scout-sharing-models";
import {
  DronePathEntry,
  IPCD,
  IStaticMapConfig,
} from "./components/3dView/models.ts";
import { ApiContext } from "../../../api/IApiClient.ts";
import { useRecordedPlayback } from "./components/3dView/hooks/useRecordedPlayback.ts";
import {
  createDroneStateObjects,
  getIndexedEntry,
  relativeTimePointClouds,
  relativeTimeTransforms,
} from "./utils.ts";
import moment from "moment";
import { useKeyboardShortcut } from "./components/3dView/hooks/useKeyboardShortcut.ts";
import {
  buildDronePathVectors,
  getEntryAtTime,
} from "./components/3dView/utils/utils.ts";
import { TabGroup } from "./components/additional-data/TabGroup.tsx";
import {
  DataSunburstFilled,
  ImageMultipleFilled,
  RulerFilled,
  Space3DRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import AdditionalData from "./components/additional-data/additional-data.tsx";
import { MeasurementPane } from "./components/measurements/measurement-pane.tsx";
import {
  PointCloudAttribute,
  loadPCD,
  getPointCloudAttributesInPrioritizedOrder,
  heightPointCloudAttribute,
  reflectivityPointCloudAttribute,
} from "./components/3dView/utils/3dutils.ts";
import { filterAndGroupFindings } from "../share/inspections/components/FindingsPane/utils.ts";
import FindingsGallery from "../share/inspections/components/FindingsPane/FindingsGallery.tsx";
import { useSearchParams } from "react-router-dom";
import { ShareHierarchyContext } from "../share/ShareHierarchyProvider.tsx";
import { ActionBarContext } from "../../../components/ActionBarProvider/ActionBarProvider.tsx";
import { Vector3 } from "three";
import VirtualScenePane from "./components/3dView/components/VirtualScenePane.tsx";
import useWindowDimensions from "../../../hooks/useWindowDimensions.tsx";
import { pointCloudColorPalettes } from "./components/3dView/materials/ScoutPointCloudMaterial.ts";
import FindingsFilters from "../share/inspections/components/FindingsPane/FindingsFilters.tsx";
import {
  Field,
  ProgressBar,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { IDroneState, ILocalizedPOIMeasurement } from "../../../interface.ts";

type LoadableData<T> = T | "loading" | "not_available";

export const hotKeyIncreasePointSize = "+";
export const hotKeyDecreasePointSize = "-";
export const hotKeyIncreasePointOpacity = "2";
export const hotKeyDecreasePointOpacity = "1";
export const hotKeyShowHidePath = "p";
export const hotKeyTogglePointColorMode = "m";
export const hotKeyTogglePointColorPalette = "c";

export const StreamerScene = (props: {
  shareId: string;
  session: ISession;
}) => {
  const apiClient = useContext(ApiContext);
  const { getResourcePath } = useContext(ShareHierarchyContext);
  const { setResourcePath, setActions } = useContext(ActionBarContext);

  // State objects
  const [selectedMeasurementIdx, setSelectedMeasurementIdx] = useState<
    number | undefined
  >();
  const [pointClouds, setPointClouds] =
    useState<LoadableData<IPCD[]>>("loading");
  const [dronePoses, setDronePoses] =
    useState<LoadableData<IDroneState[]>>("loading");
  const [localizedMeasurements, setLocalizedMeasurements] = useState<
    ILocalizedPOIMeasurement[]
  >([]);
  const [currentDroneState, setCurrentDroneState] = useState<
    IDroneState | undefined
  >(undefined);
  const [currentPointCloud, setCurrentPointCloud] = useState<IPCD | undefined>(
    undefined,
  );

  const [globalMapConfig, setGlobalMapConfig] = useState<IGlobalMapConfig>({
    enabled: false,
    file: undefined,
    points: undefined,
    transforms: undefined,
  });

  const [pcdLoadProgress, setPcdLoadProgress] = useState<
    | {
        lengthComputable: true;
        loadedMB: number;
        totalMB: number;
        fraction: number;
        done: boolean;
      }
    | { lengthComputable: false; done: boolean }
    | null
  >(null);

  const [staticMapConfig] = useState<IStaticMapConfig>({
    enabled: false,
    loaded: false,
  });

  const [pointCloudAttributes, setPointCloudAttributes] = useState<
    PointCloudAttribute[]
  >([]);
  const [pointColorPalette, setPointColorPalette] = useState<number>(1);

  const [localMapPointSize, setLocalMapPointSize] = useState<number>(0.02);
  const [localMapPointOpacity, setLocalMapPointOpacity] = useState<number>(1.0);
  const [localMapPointCloudAttribute, setLocalMapPointCloudAttribute] =
    useState<PointCloudAttribute>(heightPointCloudAttribute);
  const [localMapdronePath, setLocalMapDronePath] = useState<{
    enabled: boolean;
    path: DronePathEntry[];
  }>({
    enabled: false,
    path: [],
  });

  const [globalMapPointSize, setGlobalMapPointSize] = useState<number>(0.01);
  const [globalMapPointOpacity, setGlobalMapPointOpacity] =
    useState<number>(0.2);
  const [globalMapPointCloudAttribute, setGlobalMapPointCloudAttribute] =
    useState<PointCloudAttribute>(reflectivityPointCloudAttribute);
  const [globalMapdronePath, setGlobalMapDronePath] = useState<{
    enabled: boolean;
    path: DronePathEntry[];
  }>({
    enabled: false,
    path: [],
  });

  const [pointSize, setPointSize] = globalMapConfig.enabled
    ? [globalMapPointSize, setGlobalMapPointSize]
    : [localMapPointSize, setLocalMapPointSize];

  const [pointOpacity, setPointOpacity] = globalMapConfig.enabled
    ? [globalMapPointOpacity, setGlobalMapPointOpacity]
    : [localMapPointOpacity, setLocalMapPointOpacity];

  const [pointCloudAttribute, setPointCloudAttribute] = globalMapConfig.enabled
    ? [globalMapPointCloudAttribute, setGlobalMapPointCloudAttribute]
    : [localMapPointCloudAttribute, setLocalMapPointCloudAttribute];

  const [dronePath, setDronePath] = globalMapConfig.enabled
    ? [globalMapdronePath, setGlobalMapDronePath]
    : [localMapdronePath, setLocalMapDronePath];

  const [initialCameraSubjectPosition, setInitialCameraSubjectPosition] =
    useState<Vector3>(new Vector3(0, 0, 0));
  const findingsToShow = useMemo(
    () => props.session.findings ?? [],
    [props.session.findings],
  );

  const [player, setPlayer] = useState<VideoJsPlayer>();

  const [searchParams, setSearchParams] = useSearchParams();

  const { width } = useWindowDimensions();

  const pcdLoadToastId = useId();
  const { dispatchToast, dismissToast, updateToast } = useToastController();

  // Variant objects (these are objects that are expected to be initialized per render
  const session = props.session;
  const hasFlightData = session.position_data !== null;
  const horizontalLayout = width > 1000;
  const {
    shareId,
    session: { id: sessionId },
  } = props;

  const filteredAndGroupedFindings = useMemo(() => {
    return filterAndGroupFindings(
      findingsToShow,
      [],
      FindingsFilters["flagged"],
    );
  }, [findingsToShow]);

  const vodStream = useMemo(
    () => props.session.vod_stream ?? [],
    [props.session.vod_stream],
  );

  // Invariant objects and callbacks (these are objects that are initialized once and only change if their dependencies
  // change)

  const dispatchPcdLoadingToast = useCallback(() => {
    dispatchToast(
      <Toast>
        <ToastTitle>Loading global map</ToastTitle>
        <ToastBody>
          <Field>
            <ProgressBar />
          </Field>
        </ToastBody>
      </Toast>,
      {
        intent: "info",
        timeout: undefined,
        toastId: pcdLoadToastId,
      },
    );
  }, [dispatchToast, pcdLoadToastId]);

  const toggleVisualizationMode = useCallback(
    (switchToMode: "local" | "global") => {
      if (switchToMode === "local") {
        if (!globalMapConfig.enabled) {
          return;
        }

        setGlobalMapConfig((prev) => ({
          ...prev,
          enabled: false,
        }));
      } else if (switchToMode === "global") {
        if (globalMapConfig.enabled) {
          return;
        }

        const globalMapUrl = globalMapConfig.file;
        const transforms = globalMapConfig.transforms;
        if (globalMapUrl === undefined || transforms === undefined) {
          return;
        }

        const pcd = globalMapConfig.points;

        // Not the first time opening the global visualization
        if (pcd !== undefined) {
          setGlobalMapConfig({
            enabled: true,
            file: globalMapUrl,
            points: pcd,
            transforms: transforms,
          });
        } else {
          // First time opening the global visualization
          (async () => {
            setGlobalMapConfig({
              enabled: false,
              file: globalMapUrl,
              points: undefined,
              transforms: globalMapConfig.transforms,
            });
            setPcdLoadProgress({ lengthComputable: false, done: false });
            dispatchPcdLoadingToast();
            const pcd = await loadPCD(globalMapUrl, (event: ProgressEvent) => {
              if (event.lengthComputable) {
                const loadedMB = Math.round(event.loaded / 1024 / 1024);
                const totalMB = Math.round(event.total / 1024 / 1024);
                const fraction = event.loaded / event.total;
                setPcdLoadProgress({
                  lengthComputable: true,
                  loadedMB,
                  totalMB,
                  fraction,
                  done: false,
                });
              }
              setGlobalMapConfig({
                enabled: false,
                file: globalMapUrl,
                points: undefined,
                transforms: transforms,
              });
            });

            setPcdLoadProgress((prev) => ({ ...prev!, done: true }));

            setGlobalMapConfig({
              enabled: true,
              file: globalMapUrl,
              points: pcd,
              transforms: transforms,
            });

            // Show drone path in global map by default
            setGlobalMapDronePath({
              enabled: true,
              path: buildDronePathVectors(
                dronePoses as IDroneState[],
                transforms,
              ),
            });
          })();
        }
      }
    },
    [
      dispatchPcdLoadingToast,
      dronePoses,
      globalMapConfig.enabled,
      globalMapConfig.file,
      globalMapConfig.points,
      globalMapConfig.transforms,
    ],
  );

  const onSelectFinding = useCallback(
    (finding: IFinding | number) => {
      const { entry } = getIndexedEntry<IFinding>(
        finding,
        findingsToShow,
        (f) => f.id.toString(),
      );
      if (entry === undefined) return;

      setSearchParams((prev) => {
        prev.set("poi", entry.id.toString());
        prev.set("atSeconds", entry.at_seconds.toString());
        prev.delete("poiDialog");
        return prev;
      });
    },
    [findingsToShow, setSearchParams],
  );

  const onSelectMeasurement = useCallback(
    (measurement: ILocalizedPOIMeasurement | number) => {
      const { entry, index } = getIndexedEntry<ILocalizedPOIMeasurement>(
        measurement,
        localizedMeasurements,
        (lm) => lm.measurement.id.toString(),
      );
      if (entry === undefined || index === undefined) return;

      setSearchParams((prev) => {
        prev.set("atSeconds", entry.droneState.relativeTime.toString());
        return prev;
      });
      setSelectedMeasurementIdx(index);
    },
    [localizedMeasurements, setSearchParams],
  );

  const atSeconds = useMemo(() => {
    const at = searchParams.get("atSeconds");
    return at === null ? undefined : parseFloat(at);
  }, [searchParams]);

  const setAtSeconds = useCallback(
    (
      timeOrResource:
        | number
        | { type: "finding" | "measurement"; id: string | number },
    ) => {
      if (typeof timeOrResource === "number") {
        setSearchParams((prev) => {
          prev.set("atSeconds", timeOrResource.toString());
          return prev;
        });
      } else {
        if (timeOrResource.type === "finding") {
          setSearchParams((prev) => {
            const finding = findingsToShow.find(
              (f) => f.id === timeOrResource.id,
            );
            if (finding === undefined) {
              return prev;
            }
            prev.set("poi", finding.id.toString());
            prev.set("atSeconds", finding.at_seconds.toString());
            return prev;
          });
        } else if (timeOrResource.type === "measurement") {
          setSearchParams((prev) => {
            const localizedMeasurement =
              localizedMeasurements[parseFloat(timeOrResource.id.toString())];
            if (localizedMeasurement === undefined) {
              return prev;
            }
            prev.set(
              "atSeconds",
              localizedMeasurement.droneState.relativeTime.toString(),
            );
            return prev;
          });
        }
      }
    },
    [findingsToShow, localizedMeasurements, setSearchParams],
  );

  const onPlayerReady = useCallback(
    (player: VideoJsPlayer) => {
      setPlayer(player);
    },
    [setPlayer],
  );

  // Hooks
  useEffect(() => {
    // Dismiss toast when component unmounts
    return () => {
      dismissToast(pcdLoadToastId);
    };
  }, [dismissToast, pcdLoadToastId]);

  useEffect(() => {
    if (pcdLoadProgress?.done) {
      dismissToast(pcdLoadToastId);
      return;
    }
    const toastBody = pcdLoadProgress?.lengthComputable ? (
      <Field
        validationMessage={`${pcdLoadProgress.loadedMB} of ${pcdLoadProgress.totalMB} MB`}
        validationState="none"
      >
        <ProgressBar value={pcdLoadProgress.fraction} max={1} />
      </Field>
    ) : (
      <Field validationMessage="Loading..." validationState="none">
        <ProgressBar />
      </Field>
    );
    updateToast({
      content: (
        <Toast>
          <ToastTitle>Loading global map</ToastTitle>
          <ToastBody>{toastBody}</ToastBody>
        </Toast>
      ),
      intent: "info",
      toastId: pcdLoadToastId,
      timeout: undefined,
    });
  }, [dismissToast, pcdLoadToastId, pcdLoadProgress, updateToast]);

  useEffect(() => {
    // Load global map by default
    if (globalMapConfig.transforms !== undefined && pcdLoadProgress === null) {
      toggleVisualizationMode("global");
    }
  }, [globalMapConfig, pcdLoadProgress, toggleVisualizationMode]);

  useEffect(() => {
    const attributes: PointCloudAttribute[] =
      getPointCloudAttributesInPrioritizedOrder(
        globalMapConfig.enabled
          ? globalMapConfig.points
          : currentPointCloud?.points,
      );
    setPointCloudAttributes(attributes);

    // Check if the currently loaded point cloud supports the currently selected attribute. If not, set it to the first one in prioritized order.
    if (!attributes.map((a) => a.code).includes(pointCloudAttribute.code)) {
      setPointCloudAttribute(attributes[0]);
    }
  }, [
    currentPointCloud?.points,
    globalMapConfig,
    pointCloudAttribute,
    setPointCloudAttribute,
  ]);

  useEffect(() => {
    if (atSeconds !== undefined) {
      player?.currentTime(atSeconds);
      player?.pause();
    } else {
      player?.play();
    }
  }, [atSeconds, player]);

  useEffect(() => {
    setActions([]);
  }, [setActions]);

  useEffect(() => {
    setResourcePath(
      getResourcePath({
        type: "session",
        id: props.session.id,
        name: props.session.video_filename,
      }),
    );
  }, [props.session, getResourcePath, setResourcePath]);

  useEffect(() => {
    let active = true;
    apiClient.getFlightData(shareId, sessionId).then((data) => {
      if (!active) return;
      const sessionStart = moment(session.start_time);
      const dronePoses = createDroneStateObjects(
        sessionStart,
        data.position_data,
      );
      setPointClouds(relativeTimePointClouds(sessionStart, data.point_clouds));
      setDronePoses(dronePoses);
      if (dronePoses.length > 0) {
        // Configure the 3D view to look at the start position of the drone.
        let start = dronePoses[0].state.position;
        if (atSeconds !== undefined) {
          // If we are starting at a non-zero position (eg. directly going to a POI timestamp)
          // use the drone position at that timestamp as the initial subject for 3D scene.
          start = getEntryAtTime(dronePoses, atSeconds, (d) => d.relativeTime)
            .state.position;
        }
        setInitialCameraSubjectPosition(start);
      }

      setLocalMapDronePath((prev) => ({
        ...prev,
        path: buildDronePathVectors(dronePoses),
      }));
      if (data.transforms.length > 0) {
        setGlobalMapConfig({
          enabled: false,
          file: data.global_point_cloud ?? undefined,
          points: undefined,
          transforms: relativeTimeTransforms(sessionStart, data.transforms),
        });
      }
      setLocalizedMeasurements(
        data.poi_measurements.map((measurement) => {
          const time = moment(measurement.time);
          const relativeTime = time.diff(
            moment(session.start_time),
            "seconds",
            true,
          );
          const droneState = getEntryAtTime(
            dronePoses,
            relativeTime,
            (p) => p.relativeTime,
          );

          return {
            measurement,
            droneState,
          };
        }),
      );
    });

    return () => {
      active = false;
    };
    // Don't include atSeconds in the dependency array since we only want to load the initial camera position when first rendering
    // TODO: This is an anti-pattern and should be fixed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, shareId, sessionId, session.start_time]);

  useRecordedPlayback(
    dronePoses !== "loading" && dronePoses !== "not_available",
    player ?? null,
    pointClouds as IPCD[],
    dronePoses as IDroneState[],
    setCurrentDroneState,
    setCurrentPointCloud,
    globalMapConfig.enabled ? globalMapConfig.transforms : undefined,
  );

  useKeyboardShortcut(
    [
      hotKeyShowHidePath,
      hotKeyIncreasePointSize,
      hotKeyDecreasePointSize,
      hotKeyDecreasePointOpacity,
      hotKeyIncreasePointOpacity,
      hotKeyTogglePointColorMode,
      hotKeyTogglePointColorPalette,
    ],
    (key) => {
      const lower = key.toLocaleLowerCase();
      const round = (n: number) => Math.round(n * 1000) / 1000;
      switch (lower) {
        case hotKeyShowHidePath:
          setDronePath((prev) => {
            return {
              ...prev,
              enabled: !prev.enabled,
            };
          });
          break;
        case hotKeyDecreasePointSize:
          setPointSize((prev) => round(Math.max(0.001, prev - 0.001)));
          break;
        case hotKeyIncreasePointSize:
          setPointSize((prev) => round(Math.min(0.1, prev + 0.001)));
          break;
        case hotKeyDecreasePointOpacity:
          setPointOpacity((prev) => round(Math.max(0.1, prev - 0.1)));
          break;
        case hotKeyIncreasePointOpacity:
          setPointOpacity((prev) => round(Math.min(1, prev + 0.1)));
          break;
        case hotKeyTogglePointColorMode:
          setPointCloudAttribute((prev) => {
            const currentIdx = pointCloudAttributes.findIndex(
              (a) => a.code === prev.code,
            );
            const nextIdx = (currentIdx + 1) % pointCloudAttributes.length;
            return pointCloudAttributes[nextIdx];
          });
          break;
        case hotKeyTogglePointColorPalette:
          setPointColorPalette(
            (prev) => (prev + 1) % pointCloudColorPalettes.length,
          );
          break;
      }
    },
  );

  // Tabs
  // Here, each tab is defined as a Tab object and then passed to the appropriate TabGroup.
  // A cleaner solution could be to use React Portal to render the tab content in the appropriate TabGroup.
  // Then, state should be kept when the layout changes. Now, it is reset.

  // These tabs are memoized since they do not rerender often
  const videoTab = useMemo(
    () => ({
      tabId: "video",
      disabled: false,
      tabName: "Video",
      tabIcon: <VideoRegular />,
      tabContent: (
        <VideoPane
          onPlayerReady={onPlayerReady}
          inspectionId={""}
          videoSrc={vodStream}
        />
      ),
    }),
    [vodStream, onPlayerReady],
  );

  const poisTab = useMemo(
    () => ({
      tabId: "pois",
      disabled: false,
      tabName: "Points of Interest",
      tabIcon: <ImageMultipleFilled />,
      tabContent: (
        <FindingsGallery
          groupedFindings={filteredAndGroupedFindings}
          handleViewInVideo={onSelectFinding}
        />
      ),
    }),
    [filteredAndGroupedFindings, onSelectFinding],
  );

  const thicknessMeasurementsTab = useMemo(
    () => ({
      tabId: "thickness_measurements",
      disabled: localizedMeasurements.length === 0,
      tabName: "Thickness Measurements",
      tabIcon: <RulerFilled />,
      tabContent: (
        <MeasurementPane
          localizedMeasurements={localizedMeasurements}
          selectedMeasurementIdx={selectedMeasurementIdx}
          selectMeasurement={onSelectMeasurement}
        />
      ),
    }),
    [localizedMeasurements, onSelectMeasurement, selectedMeasurementIdx],
  );

  // These tabs are not memoized since they rerender when currentDroneState updates (which happens frequently during playback)
  const additionalDataTab = {
    tabId: "additional_data",
    disabled: !hasFlightData,
    tabName: "Additional Data",
    tabIcon: <DataSunburstFilled />,
    tabContent: <AdditionalData droneState={currentDroneState} />,
  };

  const virtualSceneTab = {
    tabId: "3d",
    disabled: !hasFlightData,
    tabName: "3D Data",
    tabIcon: <Space3DRegular />,
    tabContent: (
      <VirtualScenePane
        currentDroneState={currentDroneState}
        initialCameraSubjectPosition={initialCameraSubjectPosition}
        currentPointCloud={currentPointCloud}
        session={session}
        findingsToShow={findingsToShow ?? []}
        localizedMeasurements={localizedMeasurements}
        pointSize={pointSize}
        pointOpacity={pointOpacity}
        pointColorPalette={pointColorPalette}
        pointCloudAttribute={pointCloudAttribute}
        pointCloudAttributes={pointCloudAttributes}
        globalMapConfig={globalMapConfig}
        staticMapConfig={staticMapConfig}
        hasFlightData={hasFlightData}
        dronePath={dronePath}
        onFindingClick={onSelectFinding}
        onMeasurementClick={onSelectMeasurement}
        onPathBubbleClick={(time) => setAtSeconds(time)}
        setPointOpacity={setPointOpacity}
        setPointSize={setPointSize}
        setPointColorPalette={setPointColorPalette}
        setPointCloudAttribute={setPointCloudAttribute}
        toggleVisualizationMode={toggleVisualizationMode}
        globalMapLoaded={pcdLoadProgress?.done ?? false}
      />
    ),
  };

  return (
    <>
      <ReflexContainer orientation={"vertical"}>
        <ReflexElement>
          <ReflexContainer
            orientation={
              !hasFlightData && horizontalLayout ? "vertical" : "horizontal"
            }
          >
            <ReflexElement>
              <TabGroup tabs={[videoTab]} activeTabId={videoTab.tabId} />
            </ReflexElement>
            <ReflexSplitter
              style={{
                width: !hasFlightData && horizontalLayout ? 5 : "100%",
                height: !hasFlightData && horizontalLayout ? "100%" : 5,
                backgroundColor: "rgb(100,100,100)",
                border: "none",
              }}
            />
            <ReflexElement style={{ overflow: "hidden" }}>
              {hasFlightData && !horizontalLayout ? (
                <TabGroup
                  tabs={[
                    virtualSceneTab,
                    poisTab,
                    additionalDataTab,
                    thicknessMeasurementsTab,
                  ]}
                  activeTabId={virtualSceneTab.tabId}
                />
              ) : (
                <TabGroup
                  tabs={[poisTab, additionalDataTab, thicknessMeasurementsTab]}
                  activeTabId={
                    !hasFlightData || findingsToShow.length > 0
                      ? poisTab.tabId
                      : additionalDataTab.tabId
                  }
                />
              )}
            </ReflexElement>
          </ReflexContainer>
        </ReflexElement>
        {hasFlightData && horizontalLayout && (
          <ReflexSplitter
            style={{
              width: 5,
              height: "100%",
              backgroundColor: "rgb(100,100,100)",
              border: "none",
            }}
          />
        )}
        {hasFlightData && horizontalLayout && (
          <ReflexElement flex={0.5} style={{ overflow: "hidden" }}>
            <TabGroup
              tabs={[virtualSceneTab]}
              activeTabId={virtualSceneTab.tabId}
            />
          </ReflexElement>
        )}
      </ReflexContainer>
    </>
  );
};
